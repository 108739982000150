import React, { useState } from 'react';
import styled from 'styled-components';
import { useBrand } from 'shared/hooks/useBrand';
import { ArrowIconForSelectByBrand } from 'features/results/constants';
import { Arrow } from 'shared/types';

interface Option {
  value: string | number;
  label: string;
}

interface Props extends React.HTMLProps<HTMLSelectElement> {
  className?: string;
  options: Option[];
}

const SelectWrapper = styled.div`
  ${({ theme }) => `
    width: 100%;
    position: relative;
    line-height: ${theme.spacing.xl};
  `}
`;

const StyledSelect = styled.select`
  ${({ theme }) => `
    width: 100%;
    height: ${theme.components.form.select.height};
    font-size: ${theme.fontSizes.md};
    font: ${theme.components.form.select.font};
    padding: ${theme.spacing.xs} 0;
    background-color: ${theme.components.form.select.backgroundColor};
    color: ${theme.components.form.select.color};
    line-height: ${theme.spacing.xl};
    border: 0;
    border-bottom: ${theme.components.form.select.borderBottom};
    border-radius: 0;
    appearance: none;
    cursor: pointer;

    &:focus,
    &:focus-visible {
      outline: ${theme.components.form.select.outlineFocus};
      border-bottom: ${theme.components.form.select.borderBottomFocus};
    }

    &:disabled {
      cursor: not-allowed;
      opacity: ${theme.components.form.select.disabledOpacity}
    }

  `}
`;

const SelectIconWrapper = styled.span<{ disabled: boolean; isHovered: boolean }>`
  ${({ disabled, isHovered, theme }) => `
    position: absolute;
    top: ${theme.components.form.select.icon.top};
    right: ${theme.components.form.select.icon.right};
    display: flex;
    justify-content: center;
    align-items: center;
    width: ${theme.components.form.select.icon.width};
    height: ${theme.components.form.select.icon.height};

    border: 2px solid transparent;
    border-radius: 100px;
    color: ${theme.colors.primary};
    pointer-events: none;
    ${disabled ? 'opacity: 0.3' : ''};

    ${
      isHovered
        ? `
      background-color: ${theme.components.form.select.icon.hoverColor};
    `
        : ''
    };
  `}
`;

const Icon = styled.svg<{ src: string }>`
  ${({ src, theme }) => `
    box-sizing: border-box;
    background-color: ${theme.components.form.select.icon.color};
    width: ${theme.components.form.select.icon.iconWidth};
    height: ${theme.components.form.select.icon.iconHeight};
    mask-repeat: no-repeat;
    mask-size: 100% 100%;
    mask-image: url(${src});
  `}
`;

const Select: React.FC<Props> = ({ className = '', options, disabled = false, ...props }) => {
  const [isHovered, setIsHovered] = useState(false);
  const [isFocused, setIsFocused] = useState(false);
  const brand = useBrand();

  const handleMouseEnter = () => {
    if (!disabled) {
      setIsHovered(true);
    }
  };

  return (
    <div>
      <SelectWrapper className={className} onMouseEnter={handleMouseEnter} onMouseLeave={() => setIsHovered(false)}>
        <StyledSelect
          className={`${className}`}
          disabled={disabled}
          {...(props as Record<string, unknown>)}
          onFocus={() => {
            setIsFocused(true);
          }}
          onBlur={() => {
            setIsFocused(false);
          }}
        >
          {Array.isArray(options) &&
            options.map((option, index) => (
              <option value={option.value} key={index}>
                {option.label}
              </option>
            ))}
        </StyledSelect>

        <SelectIconWrapper disabled={disabled} isHovered={isHovered}>
          <Icon
            src={
              isFocused
                ? ArrowIconForSelectByBrand[Arrow.COLLAPSE][brand]
                : ArrowIconForSelectByBrand[Arrow.EXPAND][brand]
            }
          />
        </SelectIconWrapper>
      </SelectWrapper>
    </div>
  );
};

export default Select;
