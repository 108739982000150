import React, { useEffect, useState } from 'react';
import AddMyVehicle from 'features/add-my-vehicle/components/AddMyVehicle';
import isUndefined from 'lodash/isUndefined';
import { BusinessJourneyType, Comparison, JourneyType, VrmLookupFormSteps } from 'shared/types';
import ConfirmVehicle from 'features/add-my-vehicle/components/ConfirmVehicle';
import { useOwnVehicleComparison } from 'features/add-my-vehicle/hooks/useOwnVehicleComparison';
import { useAnalytics } from 'shared/hooks/useAnalytics';
import { useBrand } from 'shared/hooks/useBrand';
import { makeAnalyticsPathPrefix } from 'shared/utils/analytics';
import { WLTP_ERROR } from 'features/add-my-vehicle/constants';
import { FormValues } from 'features/detailed-comparator/types';
import { Product } from 'shared/constants';

interface Props {
  onVrmConfirmation: (data: Comparison, vrm: string, value: string) => void;
  journeyType: JourneyType;
  businessJourneyType: BusinessJourneyType;
  formValues?: FormValues;
  product: Product;
  onCancel: (event?: KeyboardEvent | React.SyntheticEvent) => void;
  setValidVehicle?: React.Dispatch<React.SetStateAction<boolean | undefined>>;
  currentMileage: string;
  loadedVrm?: string;
}

const VrmLookup: React.FC<Props> = ({
  onVrmConfirmation,
  journeyType,
  businessJourneyType,
  formValues,
  product,
  onCancel,
  setValidVehicle,
  currentMileage,
  loadedVrm = '',
}) => {
  const brand = useBrand();
  const [localCurrentMileage, setLocalCurrentMileage] = useState<string>('');
  const analyticsPrefix =
    product === Product.COMPARATOR ? makeAnalyticsPathPrefix(brand, journeyType, businessJourneyType) : '';

  const { logEvent } = useAnalytics(analyticsPrefix);

  const [vrm, setVrm] = useState(loadedVrm);

  const [formStep, setFormStep] = useState(VrmLookupFormSteps.ENTER_REG);

  const [shouldFetchVehicle, setShouldFetchVehicle] = useState(false);

  const method = !isUndefined(formValues) ? 'POST' : 'GET';

  const [invalidVrm, setInvalidVrm] = useState('');

  const { error, data, isLoading } = useOwnVehicleComparison(
    shouldFetchVehicle,
    vrm,
    brand,
    product,
    method,
    formValues
  );

  // initialise local current mileage on mount
  useEffect(() => {
    setLocalCurrentMileage(currentMileage);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (shouldFetchVehicle && data != null) {
      setFormStep(VrmLookupFormSteps.CONFIRM_VEHICLE);
      if (setValidVehicle) {
        setValidVehicle(true);
      }
    }
  }, [data, shouldFetchVehicle]);

  useEffect(() => {
    if (loadedVrm) {
      setVrm(vrm);
      setShouldFetchVehicle(true);
    }
  }, [loadedVrm]);

  useEffect(() => {
    if (!isUndefined(error) && vrm !== invalidVrm) {
      setInvalidVrm(vrm);
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      if (String(error) !== WLTP_ERROR) {
        logEvent('/change-form/add-my-vehicle/error/vrm-not-found');
      } else {
        logEvent('/change-form/add-my-vehicle/error/wltp-data-missing');
      }
    }
  }, [error]);

  const handleAddOwnVehicle = async (vrm: string): Promise<void> => {
    await logEvent('/change-form/add-my-vehicle/search-vrm');

    setVrm(vrm);
    setShouldFetchVehicle(true);
  };

  const handleChangeVrm = async (): Promise<void> => {
    await logEvent('/add-vehicle-form/find-a-different-vehicle');
    setLocalCurrentMileage('');
    setVrm('');
    setShouldFetchVehicle(false);
    setFormStep(VrmLookupFormSteps.ENTER_REG);
    if (setValidVehicle) {
      setValidVehicle(false);
    }
  };

  return (
    <div>
      {formStep === VrmLookupFormSteps.ENTER_REG && (
        <AddMyVehicle
          isLoading={isLoading}
          onSubmit={handleAddOwnVehicle}
          product={product}
          error={error}
          onCancel={onCancel}
        />
      )}

      {formStep === VrmLookupFormSteps.CONFIRM_VEHICLE && !isUndefined(data) && (
        <ConfirmVehicle
          product={product}
          currentMileage={localCurrentMileage}
          data={data}
          onSubmit={onVrmConfirmation}
          onChangeVrm={handleChangeVrm}
          onCancel={onCancel}
          onChangeCurrentMileage={setLocalCurrentMileage}
        />
      )}
    </div>
  );
};

export default VrmLookup;
