import React, { useEffect, useRef } from 'react';
import FocusLock from 'react-focus-lock';
import { useEventListener } from 'shared/hooks/useEventListener';
import { useTheme } from 'shared/hooks/useTheme';
import { useBrand } from 'shared/hooks/useBrand';
import { KeyboardKey } from 'shared/types';
import { ShowCloseIcon } from 'features/detailed-comparator/components/Modal/constants';

const EVENT_KEYS = [KeyboardKey.ESCAPE, KeyboardKey.ESC];

export enum ModalType {
  default = 'default',
  totalCostOfOwnership = 'totalCostOfOwnership',
}

interface Props {
  children: React.ReactNode;
  onClose: (event?: KeyboardEvent | React.SyntheticEvent) => void;
  maxWidth?: number;
  offset?: number;
  modalType?: string;
  className?: string;
}

const Modal: React.FC<Props> = ({
  onClose,
  children,
  className = '',
  maxWidth = 550,
  offset = 0,
  modalType = ModalType.default,
}) => {
  const theme = useTheme();
  const brand = useBrand();
  const modalInnerRef = useRef<HTMLInputElement>(null);
  const modalOuterRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    document.body.classList.toggle('modal-open');

    if (window.top !== window.self && 'parentIFrame' in window) {
      if (modalInnerRef.current && modalOuterRef.current) {
        const heightOfOuter = modalOuterRef.current.getBoundingClientRect().height;
        const heightOfInner = modalInnerRef.current.getBoundingClientRect().height;
        const distanceFromTopOfOuterToInner = (heightOfOuter - heightOfInner) / 2;

        window.parentIFrame.scrollToOffset(0, document.body.offsetTop - offset + distanceFromTopOfOuterToInner);
      }
    }

    return () => {
      document.body.classList.toggle('modal-open');
    };
  });

  useEventListener({
    event: 'keydown',
    handler: (event: KeyboardEvent): void => {
      if (EVENT_KEYS.includes(event.key as KeyboardKey)) {
        onClose();
      }
    },
  });

  return (
    <section className="modal" tabIndex={1} role="dialog" aria-modal="true" ref={modalOuterRef}>
      <div className="modal__inner" ref={modalInnerRef}>
        <FocusLock autoFocus={false}>
          <div className={`modal__content ${className}`}>{children}</div>

          {ShowCloseIcon[brand] === true && (
            <button className="modal__close" onClick={onClose} data-test="modal-close" tabIndex={0} aria-label="close">
              {/* @TODO remove hard coding and use translations https://drivvn.atlassian.net/browse/COMPRO-546 */}
              Close modal
            </button>
          )}
        </FocusLock>
      </div>

      <style jsx>
        {`
          .modal {
            position: fixed;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            z-index: 1000;
            display: flex;
            justify-content: center;
            align-items: start;
          }
          .modal:before {
            content: '';
            position: absolute;
            width: 100%;
            height: 100%;
            background-color: ${theme.components.modal.background.color};
            opacity: 0.8;
          }

          .modal__inner {
            position: relative;
            background: ${theme.components.modal.backgroundColor};
            margin: 0;
            width: 100%;
            min-height: 150px;
            overflow-y: auto;
            overflow-x: hidden;
            max-height: 100%;
            animation: showModalContent 0.5s forwards;
          }

          .modal__close {
            position: absolute;
            top: ${theme.components.modal.closeButton.position.top};
            right: ${theme.components.modal.closeButton.position.right};
            z-index: 2;
            height: ${theme.spacing.xxl};
            width: ${theme.spacing.xxl};
            cursor: pointer;
            border: 0;
            background: none;
            text-indent: -9999px;
            overflow: hidden;
          }
          .modal__close:before,
          .modal__close:after {
            content: '';
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: ${theme.spacing.xl};
            height: 0.125rem;
            background-color: ${theme.modal[modalType].close.color};
            opacity: ${theme.modal[modalType].close.opacity};
          }
          .modal__close:before {
            transform: translate(-50%, -50%) rotate(45deg);
          }
          .modal__close:after {
            transform: translate(-50%, -50%) rotate(-45deg);
          }
          .modal__close:focus,
          .modal__close:focus-visible {
            outline: thin dotted ${theme.colors.tertiary};
          }

          @keyframes showModalContent {
            from {
              opacity: 0;
              top: -50%;
            }
            to {
              opacity: 1;
              top: 0;
            }
          }

          @media (min-width: 900px) {
            @keyframes showModalContent {
              from {
                opacity: 0;
                top: 0%;
              }
              to {
                opacity: 1;
                top: 50%;
                transform: translateY(-50%);
              }
            }

            .modal__inner {
              animation: showModalContent 0.5s forwards;
              max-width: ${maxWidth}px;
              max-height: calc(100% - 4rem);
            }
          }
        `}
      </style>
    </section>
  );
};

export default Modal;
