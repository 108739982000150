import React from 'react';
import { useTranslation } from 'next-i18next';
import { useTheme } from 'shared/hooks/useTheme';
import { PrimaryButton, SecondaryButton } from 'shared/components/Button';

interface Props {
  text: string;
  translationKey: string;
  onContinue?: () => void;
  onCancel?: (isVisible: boolean) => void;
  showIconError?: boolean;
}

const Prompt: React.FC<Props> = ({ text, onContinue, onCancel, translationKey, showIconError = false }) => {
  const theme = useTheme();

  const { t: translate } = useTranslation(translationKey);

  const handleClick = (): void => {
    if (onCancel != null) {
      onCancel(false);
    }
  };

  return (
    <div className="prompt" data-test="prompt">
      {showIconError && (
        <div className="image-error">
          <img className="full-width" role="presentation" alt="error" src="/error.svg" />
        </div>
      )}
      <p className="prompt__text">{text}</p>

      <div className="prompt__buttons">
        {onContinue != null && (
          <PrimaryButton
            className="prompt__buttons--continue"
            text={translate('prompt.continue')}
            onClick={onContinue}
            data-test="prompt__buttons--continue"
          />
        )}
        {onCancel != null && (
          <SecondaryButton
            className="prompt__buttons--cancel"
            text={translate('prompt.cancel')}
            onClick={handleClick}
            data-test="prompt__buttons--cancel"
          />
        )}
      </div>
      <style jsx>
        {`
          .prompt {
            display: flex;
            flex-direction: ${theme.components.prompt.flexDirection};
            align-items: ${theme.components.prompt.alignItems};
            margin: ${theme.components.prompt.margin};
          }

          .prompt__buttons {
            display: flex;
            justify-content: center;
            flex-direction: ${theme.components.prompt.buttons.direction};
            width: ${theme.components.prompt.buttons.width};
            margin: ${theme.components.prompt.buttons.margin};
          }

          :global(.prompt > div > .prompt__buttons--cancel) {
            width: ${theme.components.prompt.buttons.width};
            margin: ${theme.components.prompt.buttons.button.margin};
          }

          .prompt__text {
            margin: ${theme.components.prompt.content.margin};
            font-family: ${theme.components.prompt.font.family};
            font-size: ${theme.spacing.lg};
            font-weight: ${theme.components.prompt.font.weight};
            text-align: ${theme.components.prompt.textAlign};
          }

          .image-error {
            width: 40px;
          }

          .full-width {
            width: 100%;
          }
        `}
      </style>
    </div>
  );
};

export default Prompt;
