import React, { useState } from 'react';
import { useTheme } from 'shared/hooks/useTheme';
import { useTranslation } from 'next-i18next';
import { PrimaryButton, SecondaryButton } from 'shared/components/Button';
import isUndefined from 'lodash/isUndefined';
import { isValidVrm } from 'features/add-my-vehicle/utils/isValidVrm';
import Label from 'shared/components/Label';
import { WLTP_ERROR, YEAR_VEHICLE_NEED_BE_SUBTRACT, ShowCancelButton } from 'features/add-my-vehicle/constants';
import { formatVrm } from 'features/add-my-vehicle/utils/formatVrm';
import { Product } from 'shared/constants';
import { useBrand } from 'shared/hooks/useBrand';
import { usePlausible } from 'next-plausible';
import Loader from 'shared/components/Loader';
import { Silhouette } from 'features/add-my-vehicle/components/Silhouette';
import styled from 'styled-components';

interface Props {
  onSubmit: (vrm: string) => void;
  error?: Error;
  onCancel: (event?: KeyboardEvent | React.SyntheticEvent) => void;
  isLoading: boolean;
  product: Product;
}

const InputLabel = styled(Label)`
  ${({ theme }) => `
    text-align: ${theme.components.form.label.textAlign};
    font-size: ${theme.components.form.label.fontSize};
  `}
`;

const AddMyVehicle: React.FC<Props> = ({ onSubmit, error, onCancel, isLoading, product }) => {
  const theme = useTheme();
  const brand = useBrand();
  const plausible = usePlausible();
  const [disabled, setDisabled] = useState<boolean>(true);

  const { t: translate } = useTranslation(`${brand}.${product}`);

  const [vrm, setVrm] = useState('');

  const [patternValidationError, setPatternValidationError] = useState('');

  const handleClick = (event: React.MouseEvent<HTMLAnchorElement>): void => {
    event.preventDefault();

    setPatternValidationError('');
    plausible('add_my_vehicle');

    const formattedVrm = formatVrm(vrm);

    isValidVrm(formattedVrm)
      ? onSubmit(formattedVrm)
      : setPatternValidationError(translate('add-my-vehicle.error.Vrm001'));
  };

  const validate = (value) => {
    if (!value) return setDisabled(true);

    const checkValidate = isValidVrm(value.toUpperCase());
    setDisabled(!checkValidate);

    if (!checkValidate) return;
    setVrm(value);
  };

  return (
    <form className="form" data-test="add-my-vehicle-form">
      <div className="form__content">
        <div className="form__vehicle">
          {isLoading ? <Loader text={translate('add-my-vehicle.searching')} largeSize /> : <Silhouette />}
        </div>
        <div className="form__body">
          <div className="form__label__content">
            <InputLabel text={translate('add-my-vehicle.input-label')} />
          </div>

          <div className="form__content__input">
            <div className="form__container__input">
              <p className="form__label" />
              <input
                type="text"
                className="form-input-field"
                tabIndex={1}
                placeholder={`${translate('add-my-vehicle.input-placeholder')}`}
                onChange={(ev) => validate(ev.target.value)}
                data-test="add-my-vehicle__input"
                maxLength={10}
              />
            </div>
          </div>
          {!isUndefined(error) && patternValidationError === '' && (
            <p className="form__label__footer form__error__message">
              {String(error) === WLTP_ERROR
                ? translate(`add-my-vehicle.error.Vrm006`, {
                    year: new Date().getFullYear() - YEAR_VEHICLE_NEED_BE_SUBTRACT,
                  })
                : translate('add-my-vehicle.error.Vrm001')}
            </p>
          )}
          {patternValidationError !== '' && (
            <div className="form__error form__label__footer">
              <img src={`/assets/${brand}/common/error.svg`} />
              <p>{patternValidationError}</p>
            </div>
          )}
          {!error && (
            <p className="form__label__footer">
              {translate(`add-my-vehicle.vehicle-before-year-cannot-be-added`, {
                year: new Date().getFullYear() - YEAR_VEHICLE_NEED_BE_SUBTRACT,
              })}
            </p>
          )}
        </div>
        <div className="form-button-group">
          <PrimaryButton
            text={`${translate('add-my-vehicle.findMyVehicle')}`}
            onClick={handleClick}
            data-test="add-my-vehicle__submit"
            disabled={disabled}
            type="submit"
          />
          {ShowCancelButton[brand] === true && (
            <SecondaryButton
              className="btn--vrm-lookup--cancel"
              text={`${translate('add-my-vehicle.cancel')}`}
              onClick={onCancel}
              data-test="add-my-vehicle__cancel"
            />
          )}
        </div>
      </div>

      <style jsx>
        {`
          .form {
            display: flex;
            flex-direction: column;
            align-items: ${theme.components.form.alignItems};
            min-height: 195px;
            margin-bottom: ${theme.components.form.margin.bottom};
            padding-left: ${theme.components.form.paddingLeft};
          }

          .form__vehicle {
            margin-top: ${theme.components.form.vehicle.margin.top.mobile};
            margin-bottom: ${theme.components.form.vehicle.margin.bottom.mobile};
            display: ${theme.components.form.vehicle.display};
            justify-content: ${theme.components.form.vehicle.justifyContent};
          }

          :global(.form__vehicle img) {
            width: ${theme.components.form.vehicle.image.width};
          }

          .form__body {
            width: ${theme.components.form.vehicle.body.width.mobile};
            background: ${theme.components.form.vehicle.body.background};
          }

          :global(.form__vehicle > svg > path) {
            fill: ${theme.components.form.vehicle.image.fill};
          }

          :global(.form__vehicle > svg) {
            transform: ${theme.components.form.vehicle.image.mobile.transform};
          }

          .form__label__content {
            font: ${theme.components.form.vehicle.font};
            padding-top: ${theme.components.form.vehicle.label.content.padding.top};
            padding-bottom: ${theme.components.form.vehicle.label.content.padding.bottom};
          }

          .form__container__input {
            display: ${theme.components.form.vehicle.label.content.input.display};
          }

          .form__label {
            background: ${theme.components.form.vehicle.label.background};
            text-align: ${theme.components.form.vehicle.label.textAlign};
            display: ${theme.components.form.vehicle.label.display};
            align-items: ${theme.components.form.vehicle.label.alignItems};
            border-radius: ${theme.components.form.vehicle.label.borderRadius};
            color: ${theme.components.form.vehicle.label.color};
            font-weight: ${theme.components.form.vehicle.label.font.weight};
            font-size: ${theme.components.form.vehicle.label.font.size};
            padding-top: ${theme.components.form.vehicle.label.padding.top};
            padding-right: ${theme.components.form.vehicle.label.padding.right};
            padding-left: ${theme.components.form.vehicle.label.padding.left};
            padding-bottom: ${theme.components.form.vehicle.label.padding.bottom};
            width: ${theme.components.form.vehicle.label.width};
            height: ${theme.components.form.vehicle.label.height};
            border-width: ${theme.components.form.vehicle.label.border.width};
            border-style: ${theme.components.form.vehicle.label.border.style};
            border-color: ${theme.components.form.vehicle.label.border.color};
          }

          .form__label__footer {
            text-align: ${theme.components.form.vehicle.label.footer.textAlign};
            padding-top: ${theme.components.form.vehicle.label.footer.padding.top};
            padding-right: ${theme.components.form.vehicle.label.footer.padding.right};
            padding-bottom: ${theme.components.form.vehicle.label.footer.padding.bottom};
            padding-left: ${theme.components.form.vehicle.label.footer.padding.left};
            color: ${theme.components.form.vehicle.label.footer.color};
          }

          .form__content {
            display: flex;
            flex-direction: column;
            align-items: ${theme.components.form.alignItems};
            margin-top: ${theme.components.form.content.marginTop};
            margin-bottom: ${theme.components.form.content.marginBottom};
            margin-left: ${theme.components.form.content.marginLeft};
            margin-right: ${theme.components.form.content.marginRight};
          }

          .form__content__input {
            display: ${theme.components.form.vehicle.label.content.input.display};
            justify-content: ${theme.components.form.vehicle.label.content.input.justifyContent};
            align-items: ${theme.components.form.vehicle.label.content.input.alignItems};
          }

          .form-input-field {
            height: ${theme.components.form.inputField.height};
            border-radius: ${theme.components.form.inputField.borderRadius};
          }

          .form__content__input input {
            background: ${theme.vrmLookup.background};
            border: ${theme.components.form.inputField.border};
            padding: ${theme.spacing.lg} ${theme.spacing.xl};
            outline: none;
            font-family: ${theme.fonts.bold};
            text-transform: uppercase;
            width: ${theme.components.form.inputField.width};
            font-weight: ${theme.components.form.inputField.fontWeight};
            font-size: ${theme.components.form.inputField.fontSize};
            text-align: ${theme.components.form.inputField.textAlign};
          }

          .form__content__input input::-webkit-input-placeholder {
            /* WebKit, Blink, Edge */
            color: ${theme.components.form.inputField.placeholder.color};
            font-family: ${theme.fonts.bold};
            font-size: ${theme.components.form.inputField.fontSize};
          }
          .form__content__input input:-moz-placeholder {
            /* Mozilla Firefox 4 to 18 */
            color: ${theme.components.form.inputField.placeholder.color};
            opacity: 1;
            font-family: ${theme.fonts.bold};
            font-size: ${theme.components.form.inputField.fontSize};
          }
          .form__content__input input::-moz-placeholder {
            /* Mozilla Firefox 19+ */
            color: ${theme.components.form.inputField.placeholder.color};
            opacity: 1;
            font-family: ${theme.fonts.bold};
            font-size: ${theme.components.form.inputField.fontSize};
          }
          .form__content__input input:-ms-input-placeholder {
            /* Internet Explorer 10-11 */
            color: ${theme.components.form.inputField.placeholder.color};
            font-family: ${theme.fonts.bold};
            font-size: ${theme.components.form.inputField.fontSize};
          }
          .form__content__input input::-ms-input-placeholder {
            /* Microsoft Edge */
            color: ${theme.components.form.inputField.placeholder.color};
            font-family: ${theme.fonts.bold};
            font-size: ${theme.components.form.inputField.fontSize};
          }

          .form__content__input input::placeholder {
            /* Most modern browsers support this now. */
            color: ${theme.components.form.inputField.placeholder.color};
            font-size: ${theme.components.form.inputField.fontSize};
            font-family: ${theme.fonts.bold};
          }

          .form-button-group {
            display: ${theme.components.form.vehicle.group.display};
            flex-direction: ${theme.components.form.vehicle.flexDirection};
            justify-content: ${theme.components.form.vehicle.group.justifyContent};
            max-width: ${theme.components.form.buttons.maxWidth};
            gap: ${theme.components.form.vehicle.group.gap};
            margin-top: ${theme.components.form.vehicle.group.mobile.margin.top};
            width: ${theme.components.form.buttons.maxWidth};
          }

          .form__error {
            display: flex;
            justify-content: center;
            max-width: 290px;
            flex-direction: ${theme.components.form.viewports.mobile.error.flexDirection};
            padding-left: ${theme.components.form.viewports.mobile.error.paddingLeft};
            padding-right: ${theme.components.form.viewports.mobile.error.paddingRight};
          }

          .form__error img {
            width: ${theme.components.form.errorIconWidth};
          }

          .form__error > p {
            margin-left: ${theme.components.form.viewports.mobile.error.textMarginLeft};
            margin-top: ${theme.components.form.viewports.mobile.error.textMarginTop};
            font-size: ${theme.components.form.errorFontSize};
          }

          .form__error__message {
            color: ${theme.components.form.vehicle.errorMessage.color};
            font-size: ${theme.components.form.vehicle.errorMessage.font.size};
            font-weight: ${theme.components.form.vehicle.errorMessage.font.weight};
          }

          @media screen and (min-width: ${theme.viewports.mobile}) {
            :global(.form__vehicle > svg) {
              transform: scale(1);
            }

            .form-button-group {
              margin-top: ${theme.components.form.vehicle.group.margin.top};
            }
          }

          @media (min-width: 900px) {
            .form__content {
              display: block;
            }

            .form__vehicle {
              margin-top: ${theme.components.form.vehicle.margin.top.desktop};
              margin-bottom: ${theme.components.form.vehicle.margin.bottom.desktop};
            }

            .form__body {
              width: ${theme.components.form.vehicle.body.width.desktop};
            }

            .form__content__input {
              display: ${theme.components.form.vehicle.label.content.input.display};
              justify-content: ${theme.components.form.vehicle.label.content.input.justifyContent};
              align-items: ${theme.components.form.vehicle.label.content.input.alignItems};
            }

            .form__content__input input {
              margin-right: ${theme.spacing.sm};
            }

            .form-button-group {
              max-width: ${theme.components.form.buttons.maxWidth};
            }

            .form__error {
              max-width: 400px;
              flex-direction: ${theme.components.form.viewports.desktop.error.flexDirection};
              padding-left: ${theme.components.form.viewports.desktop.error.paddingLeft};
              padding-right: ${theme.components.form.viewports.desktop.error.paddingRight};
              margin-left: ${theme.components.form.viewports.desktop.error.marginLeft};
            }

            .form__error > p {
              margin-left: ${theme.components.form.viewports.desktop.error.textMarginLeft};
              margin-top: ${theme.components.form.viewports.desktop.error.textMarginTop};
            }
          }
        `}
      </style>
    </form>
  );
};

export default AddMyVehicle;
