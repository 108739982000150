import React from 'react';
import styled from 'styled-components';
import { Container } from 'shared/components/Container';

const TitleAndDescriptionWrapper = styled(Container)`
  ${({ theme }) => `
    &&& {
      display: flex;
      font-family: ${theme.fonts.medium};
      font-weight: 500;
      flex-direction: column;
      padding-top: ${theme.components.titleAndDescription.padding.top[0]};
      padding-bottom: ${theme.components.titleAndDescription.padding.bottom[0]};

      @media screen and (min-width: ${theme.viewports.desktop}) {
        padding-top: ${theme.components.titleAndDescription.padding.top[1]};
        padding-bottom: ${theme.components.titleAndDescription.padding.bottom[1]};
      };
    }
  `}
`;

const Title = styled.h1`
  margin-bottom: 24px;
  font-size: ${({ theme }) => theme.fontSizes.mobile.heading1};

  @media screen and (min-width: ${({ theme }) => theme.viewports.desktop}) {
    font-size: ${({ theme }) => theme.fontSizes.desktop.heading1};
  }
`;

const Description = styled.h3`
  font-size: ${({ theme }) => `${theme.fontSizes.mobile.heading3}`};
  font-family: ${({ theme }) => `${theme.fonts.regular}`};

  @media screen and (min-width: ${({ theme }) => theme.viewports.desktop}) {
    font-size: ${({ theme }) => theme.fontSizes.desktop.heading3};
  }
`;

interface Props {
  className?: string;
  title: string;
  description: string;
}

export const TitleAndDescription: React.FC<Props> = ({ className, title, description }) => (
  <TitleAndDescriptionWrapper className={className} data-test="title-and-description-component">
    <Title data-test="title-component">{title}</Title>
    <Description data-test="description-component">{description}</Description>
  </TitleAndDescriptionWrapper>
);
