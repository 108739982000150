import { Brand } from 'shared/types';

const ShowCloseIcon = {
  [Brand.AUDI]: false,
  [Brand.VOLVO]: true,
  [Brand.FORD]: true,
  [Brand.WAYLANDS]: true,
  [Brand.DRIVVN]: true,
  [Brand.DRIVVNQA]: true,
  [Brand.VOLKSWAGEN]: true,
};

export { ShowCloseIcon };
