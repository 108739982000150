import React from 'react';
import styled from 'styled-components';

interface Props extends React.HTMLProps<HTMLLabelElement> {
  className?: string;
  text: string;
  disabled?: boolean;
}

const StyledLabel = styled.label<{ disabled: boolean }>`
  ${({ disabled, theme }) => `
    display: block;
    margin-bottom: ${theme.components.form.label.marginBottom};
    font-family: ${theme.components.form.select.label.font.family};
    font-weight: ${theme.components.form.select.label.font.weight};
    ${disabled ? `opacity: ${theme.components.form.select.disabledOpacity}` : ''};
  `}
`;

const Label: React.FC<Props> = ({ className = '', text, disabled = false, ...props }) => {
  return (
    <StyledLabel className={className} {...(props as Record<string, unknown>)} disabled={disabled}>
      {text}
    </StyledLabel>
  );
};

export default Label;
