import React from 'react';
import { Comparison } from 'shared/types';
import { useTheme } from 'shared/hooks/useTheme';
import { useBrandConfig } from 'shared/hooks/useBrandConfig';
import { PrimaryButton, SecondaryButton } from 'shared/components/Button';
import { Trans, useTranslation } from 'next-i18next';
import Link from 'shared/components/Link';
import { useBrand } from 'shared/hooks/useBrand';
import { Product } from 'shared/constants';
import { MAX_CURRENT_MILEAGE, MIN_CURRENT_MILEAGE } from 'features/add-my-vehicle/constants';
import { ChevronRight } from 'features/results/components/icons';
import { makeVehicleImageUrl } from 'shared/utils/makeVehicleImageUrl';
import { styled } from 'styled-components';

interface Props {
  data: {
    vrm: string;
    vehicleData: Comparison;
  };
  onCancel: (event: KeyboardEvent | React.SyntheticEvent) => void;
  onSubmit: (data: Comparison, vrm: string, value: string) => void;
  onChangeVrm: () => void;
  currentMileage: string;
  product: Product;
  onChangeCurrentMileage: (string: string) => void;
}

const OptionalContent = styled.span`
  ${({ theme }) => `
    color: ${theme.components.vehicleConfirm.currentMileage.form.label.color.optional};
  `}
`;

const ConfirmVehicle: React.FC<Props> = ({
  data,
  onSubmit,
  onChangeVrm,
  onCancel,
  currentMileage,
  product,
  onChangeCurrentMileage,
}) => {
  const theme = useTheme();
  const brand = useBrand();
  const brandConfig = useBrandConfig();
  const detailedComparatorBrandConfig = brandConfig[product];
  const shouldShowCurrentMileageInput =
    detailedComparatorBrandConfig?.features?.currentMileage?.shouldShowCurrentMileageInput;

  const { t: translate } = useTranslation(`${brand}.${product}`);

  const { vrm } = data;

  const { id, model, description, manufacturer } = data.vehicleData;

  const handleSubmit: React.FormEventHandler<HTMLFormElement> = (event): void => {
    event.preventDefault();

    onSubmit(data.vehicleData, data.vrm, currentMileage);
  };

  const handleCancel = (event: KeyboardEvent | React.SyntheticEvent): void => {
    onCancel(event);
  };

  return (
    <form className="confirm-vehicle" onSubmit={handleSubmit}>
      <div className="confirm-vehicle__image">
        <img src={makeVehicleImageUrl(id, manufacturer)} title={model} alt={model} data-test="confirm_vehicle__image" />
      </div>

      <div className="confirm-vehicle__details">
        <p className="confirm-vehicle__details__vrm" data-test="confirm-vehicle__details__vrm">
          {vrm}
        </p>
        <p className="confirm-vehicle__details__description" data-test="confirm-vehicle__details__description">
          {description}
        </p>
        <div className="confirm-vehicle__details__findAnotherVehicle">
          <Link
            href="#"
            text="Find a different vehicle"
            onClick={onChangeVrm}
            data-test="confirm-vehicle__change-vehicle"
            icon={brandConfig[Product.ALL].confirmVehicle.hideIcon ? undefined : ChevronRight}
          />
        </div>
      </div>

      {shouldShowCurrentMileageInput ? (
        <div className="confirm-vehicle__current-mileage">
          <div className="confirm-vehicle__current-mileage__form">
            <p className="current-mileage__form__label">
              <Trans
                i18nKey="add-my-vehicle.current-mileage-label"
                ns={`${brand}.${Product.DETAILED_COMPARATOR}`}
                components={{
                  span: <OptionalContent />,
                }}
              />
            </p>
            <input
              data-test="current-mileage-form-input"
              type="number"
              min={MIN_CURRENT_MILEAGE}
              max={MAX_CURRENT_MILEAGE}
              value={currentMileage}
              className="current-mileage__form__input"
              onChange={(e) => onChangeCurrentMileage(e.target.value)}
              step={1000}
            />
          </div>
          <p className="confirm-vehicle__current-mileage__description">
            {translate('add-my-vehicle.current-mileage-description')}
          </p>
        </div>
      ) : null}

      <div
        className={`confirm-vehicle__action-buttons confirm-vehicle__action-buttons--${Product.DETAILED_COMPARATOR}`}
      >
        <PrimaryButton
          className="btn--confirm-vehicle"
          text={`${translate('add-my-vehicle.confirm-vehicle.button')}`}
          type="submit"
        />
        <SecondaryButton
          className="btn--confirm-vehicle"
          onClick={handleCancel}
          text={`${translate('add-my-vehicle.cancel')}`}
          data-test="add-my-vehicle__cancel"
        />
      </div>

      <style jsx>
        {`
          .confirm-vehicle {
            display: flex;
            flex-direction: column;
            align-items: center;
          }

          .confirm-vehicle__details {
            text-align: center;
            padding: ${theme.spacing.md};
            margin-bottom: ${theme.spacing.md};
          }

          .confirm-vehicle__details__findAnotherVehicle {
            font: ${theme.components.vehicleConfirm.findAnotherVehicle.font};
            color: ${theme.components.vehicleConfirm.findAnotherVehicle.color};
            cursor: ${theme.components.vehicleConfirm.findAnotherVehicle.cursor};
            margin-top: ${theme.components.vehicleConfirm.findAnotherVehicle.marginTop};
          }

          .confirm-vehicle__current-mileage {
            max-width: ${theme.components.vehicleConfirm.currentMileage.maxWidth};
            width: ${theme.components.vehicleConfirm.currentMileage.width};
            display: ${theme.components.vehicleConfirm.currentMileage.display};
            flex-direction: ${theme.components.vehicleConfirm.currentMileage.flexDirection};
            align-items: ${theme.components.vehicleConfirm.currentMileage.alignItems};
            padding: ${theme.components.vehicleConfirm.currentMileage.padding};
          }

          .confirm-vehicle__current-mileage__description {
            max-width: ${theme.components.vehicleConfirm.currentMileage.description.maxWidth.mobile};
            color: ${theme.components.vehicleConfirm.currentMileage.description.color};
            font: ${theme.components.vehicleConfirm.currentMileage.description.font};
            margin-top: ${theme.components.vehicleConfirm.currentMileage.description.marginTop};
            text-align: ${theme.components.vehicleConfirm.currentMileage.description.textAlign};
          }

          .confirm-vehicle__action-buttons {
            display: ${theme.components.vehicleConfirm.actionButtons.display};
            margin-bottom: ${theme.components.vehicleConfirm.actionButtons.marginBottom};
            margin-top: ${theme.components.vehicleConfirm.actionButtons.marginTop};
            flex-direction: ${theme.components.vehicleConfirm.actionButtons.flexDirection};
            width: ${theme.components.vehicleConfirm.actionButtons.width.mobile};
            align-items: ${theme.components.vehicleConfirm.actionButtons.alignItems};
            padding: 0 16px;
          }

          :global(.confirm-vehicle__action-buttons button) {
            width: ${theme.components.vehicleConfirm.actionButtons.width.mobile};
            margin: 8px 16px !important;
          }

          .confirm-vehicle__action-buttons--detailed-comparator {
            margin-top: ${theme.components.vehicleConfirm.actionButtons.marginTop};
          }

          .confirm-vehicle__current-mileage__form {
            align-items: ${theme.components.vehicleConfirm.currentMileage.form.label.alignment};
            display: flex;
            flex-direction: column;
            width: 100%;
          }

          .confirm-vehicle__current-mileage__form > p {
            margin-bottom: ${theme.components.vehicleConfirm.currentMileage.form.label.marginBottom};
            font: ${theme.components.vehicleConfirm.currentMileage.form.label.font};
          }

          .confirm-vehicle__current-mileage__form > input[type='number'] {
            font: ${theme.components.vehicleConfirm.currentMileage.form.input.font};
            text-align: ${theme.components.vehicleConfirm.currentMileage.form.input.textAlign};
            height: ${theme.components.vehicleConfirm.currentMileage.form.input.height};
            width: ${theme.components.vehicleConfirm.currentMileage.form.input.width.mobile};
            border-width: ${theme.components.vehicleConfirm.currentMileage.form.input.border.width};
            border-style: ${theme.components.vehicleConfirm.currentMileage.form.input.border.style};
            border-color: ${theme.components.vehicleConfirm.currentMileage.form.input.border.color};
            color: ${theme.components.vehicleConfirm.currentMileage.form.input.color};
            -moz-appearance: textfield;
            -webkit-appearance: none;
            padding: ${theme.components.vehicleConfirm.currentMileage.form.input.padding};
          }

          confirm-vehicle__current-mileage__form > input::-webkit-outer-spin-button,
          input::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
          }

          .confirm-vehicle__image {
            display: flex;
            flex-direction: row;
            justify-content: center;
          }

          .confirm-vehicle__image > img {
            width: ${theme.components.vehicleConfirm.image.width.mobile};
          }

          .confirm-vehicle__details__vrm {
            font: ${theme.components.vehicleConfirm.details.vrm.font};
          }

          .confirm-vehicle__details__description {
            color: ${theme.components.vehicleConfirm.details.description.color};
            font: ${theme.components.vehicleConfirm.details.description.font};
            margin-top: ${theme.components.vehicleConfirm.details.description.margin.top};
          }

          .current-mileage__form__label {
            text-align: ${theme.components.vehicleConfirm.currentMileage.form.label.textAlign};
            width: ${theme.components.vehicleConfirm.currentMileage.form.input.width.mobile};
            color: ${theme.components.vehicleConfirm.currentMileage.form.label.color.default};
          }

          .confirm-vehicle__change-vehicle {
            text-align: center;
            margin: ${theme.spacing.xxl};
          }

          .confirm-vehicle__change-vehicle > h3 {
            color: ${theme.colors.tertiary};
            margin-bottom: ${theme.spacing.xs};
          }

          .change-vehicle__link {
            cursor: pointer;
            color: ${theme.colors.primary};
            background: none;
            border: none;
          }

          .confirm-vehicle__cancel {
            margin: ${theme.components.vehicleConfirm.actionButtons.cancel.margin};
          }

          @media (min-width: 900px) {
            .confirm-vehicle__submit {
              margin-bottom: ${theme.spacing.xxl};
            }

            .confirm-vehicle__cancel {
              margin-bottom: ${theme.spacing.xxl};
            }

            .confirm-vehicle__image > img {
              width: ${theme.components.vehicleConfirm.image.width.desktop};
            }

            .confirm-vehicle__current-mileage__description {
              max-width: ${theme.components.vehicleConfirm.currentMileage.description.maxWidth.desktop};
            }

            .current-mileage__form__label {
              width: ${theme.components.vehicleConfirm.currentMileage.form.input.width.desktop};
            }

            .confirm-vehicle__current-mileage__form > input[type='number'] {
              width: ${theme.components.vehicleConfirm.currentMileage.form.input.width.desktop};
            }

            .confirm-vehicle__action-buttons {
              width: ${theme.components.vehicleConfirm.actionButtons.width.desktop};
            }

            :global(.confirm-vehicle__action-buttons button) {
              width: ${theme.components.vehicleConfirm.actionButtons.width.desktop};
            }
          }
        `}
      </style>
    </form>
  );
};

export default ConfirmVehicle;
