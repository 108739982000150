import { Product } from 'shared/constants';
import { Brand } from 'shared/types';

export const WLTP_ERROR = 'Error: Vrm006';

export const MAX_CURRENT_MILEAGE = 999999;
export const MIN_CURRENT_MILEAGE = 0;

export const ShowCancelButton = {
  [Brand.AUDI]: true,
  [Brand.FORD]: true,
  [Brand.VOLVO]: true,
  [Brand.WAYLANDS]: true,
  [Brand.DRIVVN]: false,
  [Brand.DRIVVNQA]: false,
  [Brand.VOLKSWAGEN]: true,
};

export const ShowCancelButtonRegistration = {
  [Brand.AUDI]: true,
  [Brand.VOLVO]: false,
  [Brand.FORD]: false,
  [Brand.WAYLANDS]: false,
  [Brand.DRIVVN]: false,
  [Brand.DRIVVNQA]: false,
};

export const YEAR_VEHICLE_NEED_BE_SUBTRACT = 7;
