import { UseQuery, useQuery } from 'shared/hooks/useQuery';
import { Brand, Comparison } from 'shared/types';
import { Product } from 'shared/constants';

interface VrmLookupResponse {
  vrm: string;
  vehicleData: Comparison;
  errors: Error[];
}

const useOwnVehicleComparison = (
  shouldFetchOwnVehicle: boolean,
  vrm: string,
  brand: Brand,
  tool: Product,
  method: 'GET' | 'POST' = 'GET',
  body?: any
): UseQuery<VrmLookupResponse> => {
  const url = `/api/vrm/${vrm}?brand=${brand}`;

  if (body !== undefined) {
    return useQuery<VrmLookupResponse>(shouldFetchOwnVehicle, url, tool, method, body);
  }

  return useQuery<VrmLookupResponse>(shouldFetchOwnVehicle, url, tool, method);
};

export { useOwnVehicleComparison };
