import React from 'react';
import { useTranslation } from 'next-i18next';
import { Product } from 'shared/constants';
import { SecondaryButton } from 'shared/components/Button';
import { useBrand } from 'shared/hooks/useBrand';
import { styled } from 'styled-components';

interface Props {
  onClick: (hasRegistrationNumber: boolean) => void;
  onCancel: (event?: KeyboardEvent | React.SyntheticEvent) => void;
  product: Product;
}

type OptionType = 'registrationNumber' | 'vehiclesList';

const AddVehicle = styled.div`
  ${({ theme }) => `
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding-top: ${theme.components.vehicleOptionsForm.mobile.padding.top};
    padding-right: ${theme.components.vehicleOptionsForm.mobile.padding.right};
    padding-bottom: ${theme.components.vehicleOptionsForm.mobile.padding.bottom};
    padding-left: ${theme.components.vehicleOptionsForm.mobile.padding.left};

    @media screen and (min-width: 900px) {
      margin: ${theme.components.vehicleOptionsForm.margin};
    }

    @media screen and (min-width: ${theme.viewports.mobile}) {
      padding: ${theme.components.vehicleOptionsForm.padding};
    }
  `}
`;

const AddVehicleTitle = styled.h1`
  ${({ theme }) => `
    font: ${theme.components.vehicleOptionsForm.title.font};
    color: ${theme.components.vehicleOptionsForm.title.color};
  `}
`;

const AddVehicleDescription = styled.p`
  ${({ theme }) => `
    font: ${theme.components.vehicleOptionsForm.description.font};
    color: ${theme.components.vehicleOptionsForm.description.color};
    margin-top: ${theme.components.vehicleOptionsForm.description.mobile.margin.top};

    @media screen and (min-width: ${theme.viewports.mobile}) {
      width: ${theme.components.vehicleOptionsForm.description.width};
      margin-top: ${theme.components.vehicleOptionsForm.description.marginTop};
    }
  `}
`;

const AddVehicleOptions = styled.div`
  ${({ theme }) => `
    display: flex;
    flex-direction: column;
    margin-top: ${theme.components.vehicleOptionsForm.options.mobile.margin.top};

    @media screen and (min-width: ${theme.viewports.mobile}) {
      flex-direction: row;
      margin-top: ${theme.components.vehicleOptionsForm.options.option.margin.top};
    }
  `}
`;

const AddVehicleButton = styled.button`
  ${({ theme }) => `
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
    background: none;
    width: ${theme.components.vehicleOptionsForm.options.container.width.mobile};
    height: ${theme.components.vehicleOptionsForm.options.container.height};
    border: ${theme.components.vehicleOptionsForm.options.container.border};
    border-radius: ${theme.components.vehicleOptionsForm.options.container.borderRadius};
    padding: 0;

    &:nth-child(1) {
      margin-bottom: ${theme.components.vehicleOptionsForm.options.option.margin.bottom};
    }

    &:hover {
      border: ${theme.components.vehicleOptionsForm.options.container.hover.border};
      background-color: ${theme.components.vehicleOptionsForm.options.container.hover.backgroundColor};
    }

    @media screen and (min-width: ${theme.viewports.mobile}) {
      width: ${theme.components.vehicleOptionsForm.options.container.width.desktop};

      &:nth-child(1) {
        margin-right: ${theme.components.vehicleOptionsForm.options.option.margin.right};
        margin-bottom: 0px;
      }
    }
  `}
`;

const AddVehicleButtonImage = styled.img<{ optionType: OptionType }>`
  ${({ theme, optionType }) => `
      content: ${theme.components.vehicleOptionsForm.options.container.icon[optionType].image.default};
      height: ${theme.components.vehicleOptionsForm.options.container.icon[optionType].image.height};
      margin-top: ${theme.components.vehicleOptionsForm.options.container.icon.marginTop};

      ${AddVehicleButton}:hover & {
        content: ${theme.components.vehicleOptionsForm.options.container.icon[optionType].image.hover};
      }
  `}
`;

const AddVehicleContent = styled.p`
  ${({ theme }) => `
      font: ${theme.components.vehicleOptionsForm.options.container.description.font};
      color: ${theme.components.vehicleOptionsForm.options.container.description.color};
      margin-top: ${theme.components.vehicleOptionsForm.options.container.description.marginTop};

      ${AddVehicleButton}:hover & {
        color: ${theme.components.vehicleOptionsForm.options.container.hover.color};
      }
  `}
`;

const AddVehicleCancelButton = styled(SecondaryButton)`
  ${({ theme }) => `
    &&& {
      margin-top: ${theme.components.vehicleOptionsForm.button.marginTop.mobile};
      width: 100%;

      @media screen and (min-width: ${theme.viewports.mobile}) {
        margin-top: ${theme.components.vehicleOptionsForm.button.marginTop.desktop};
      }
    }
  `}
`;

const AddVehicleOptionsForm: React.FC<Props> = ({ onClick, onCancel, product }) => {
  const brand = useBrand();
  const namespace = `${brand}.${product}`;

  const { t: translate } = useTranslation(namespace);

  return (
    <AddVehicle data-test="vehicle-options">
      <AddVehicleTitle>{translate('add-vehicle-form-options.title')}</AddVehicleTitle>
      <AddVehicleDescription>{translate('add-vehicle-form-options.description')}</AddVehicleDescription>
      <AddVehicleOptions>
        <AddVehicleButton type="button" onClick={() => onClick(true)} data-test="add-vehicle-option-0" tabIndex={0}>
          <AddVehicleButtonImage alt="Look up a registration number" optionType="registrationNumber" />
          <AddVehicleContent>{translate('add-vehicle-form-options.registration-number.description')}</AddVehicleContent>
        </AddVehicleButton>
        <AddVehicleButton type="button" onClick={() => onClick(false)} data-test="add-vehicle-option-1" tabIndex={0}>
          <AddVehicleButtonImage alt="Search through the list of vehicles" optionType="vehiclesList" />
          <AddVehicleContent>{translate('add-vehicle-form-options.find-vehicle.description')}</AddVehicleContent>
        </AddVehicleButton>
      </AddVehicleOptions>

      <AddVehicleCancelButton
        text={translate('add-vehicle-form.cancel')}
        onClick={onCancel}
        data-test="add-vehicle-option__cancel-button"
      />
    </AddVehicle>
  );
};

export default AddVehicleOptionsForm;
